import React, { FunctionComponent, useEffect } from 'react';
import { GetStaticProps } from 'next';
import { useDispatch } from 'react-redux';
import { ParsedUrlQuery } from 'querystring';
import styled from 'styled-components';
import IndexHead from '../components/IndexHead';
import { updateStrings } from '../store/string';
import { prismicConstants } from '../utils/Constants';
import LayoutWrapper from '../components/layout/LayoutWrapper';
import SliceZone from '../components/SliceZone';
import {
  OnboardingPage,
  ErrorStrings,
  OnboardingPageBodyNavigation,
  OnboardingPageBodyModalgroup,
} from '../types/PrismicTypes';
import { getNodesFromConnection, getStrings } from '../utils/Prismic/PrismicBaseUtils';
import OnboardingPrismicClient from '../utils/Prismic/OnboardingPrismicClient';
import { PreviewData, Lang } from '../types/Types';

type Props = {
  onboardingPage: OnboardingPage;
  errorStrings: ErrorStrings[];
};

const StyledLoginContainer = styled.div`
  display: flex;
  height: 100%;
  h3 {
    font-size: 70px;
    line-height: 68px;
  }
`;

const Home: FunctionComponent<Props> = ({ onboardingPage, errorStrings }: Props) => {
  const dispatch = useDispatch();
  const body = onboardingPage.body ?? [];
  useEffect(() => {
    dispatch(updateStrings(getStrings(errorStrings)));
  }, [errorStrings, dispatch]);
  return (
    <StyledLoginContainer>
      <IndexHead />
      <LayoutWrapper onboardingPage={onboardingPage}>
        <SliceZone
          slices={body.filter((slice) => slice.type !== 'navigation' && slice.type !== 'modalgroup')}
          navigationSlice={body.find((slice) => slice.type === 'navigation') as OnboardingPageBodyNavigation}
          modalGroupSlice={body.find((slice) => slice.type === 'modalgroup') as OnboardingPageBodyModalgroup}
        />
      </LayoutWrapper>
    </StyledLoginContainer>
  );
};

interface Params extends ParsedUrlQuery {
  uid: string;
}

export const getStaticProps: GetStaticProps<Props, Params> = async ({ previewData }) => {
  const prismicClient = new OnboardingPrismicClient(prismicConstants.apiToken, prismicConstants.prismicRef);
  const uId = 'innskraning';
  const variables = { lang: Lang.IS, uId };

  const response = await prismicClient.getOnboardingPageByUid({
    previewData: previewData as PreviewData,
    variables,
  });

  const onboardingPages: OnboardingPage[] = getNodesFromConnection(response);
  // We should always have one onboarding page returned
  const onboardingPage = onboardingPages && onboardingPages.length > 0 ? onboardingPages[0] : null;
  if (!onboardingPage) {
    // Throw this error so that the build fails if we have a undefined onboarding page
    // This also deals with the typing error since onboardingPage is always expected to be truthy
    throw new Error('Prismic returned unexpected result');
  }

  const errorStringResponse = await prismicClient.getErrorStrings({
    previewData: previewData as PreviewData,
    variables,
  });

  const errorStrings: ErrorStrings[] = getNodesFromConnection(errorStringResponse);

  return {
    props: {
      onboardingPage,
      errorStrings,
    },
  };
};

export default Home;
